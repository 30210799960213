<template>
  <div class="wrapper" style="background-color: #02032f">
    <Slider></Slider>

    <div style="
        color: black;
        border-bottom: solid var(--light-gray) 1px;
        padding: 0;
      " class="d-block">
      <span class="ml-2" style="color: #A0ACDC; font-weight: 600; float: left;font-size:medium;">Popular
        games</span>
      <span style="margin-top: 6px; float: right;">
        <router-link to="/casino">
          <span class="mr-2" style="color: #A0ACDC; font-weight: 600;text-decoration: underline;">
            View All
            <img style="width: 20px; height: 20px;" src="/img/icons/bb-icons/icons-eye.png"
              class="menu-icon-sports-svg" />
          </span>
        </router-link>
      </span>
      <div style="width: 100%; background-color: #02032f; padding: 5px" class="row no-gutters justify-content-center">
        <div class="games-container">
          <div v-for="(game, index) in homePagePopularGames" :key="getKey(index)" class="casino-game">
            <div class="">
              <div class="game-icon">
                <router-link
                  style="background-color: #16182d;border-radius: 10px;border: solid var(--lightest-gray) 1px;"
                  class="card"
                  :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: game.provider_id, gameName: game.game_name, gameID: game.game_id, launchURL: game.launch_url, launchType: game.launch_type, launchParameters: game.launch_parameters }, }">
                  <img loading="lazy" :src="game.image" alt="Avatar"
                    style="width: 120px;height: 120px;object-fit: cover;border-top-left-radius: 10px;border-top-right-radius: 10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;padding: 0;background-color: var(--lightest-gray);border: solid var(--light-gray) 1px;" />
                  <!-- <h6 style="color: var(--white);text-align: left;margin-left: 8px;"> <strong>{{
                    truncateText(game.gameName) }}</strong>
                  </h6> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <marquee class="marquee-container mt-0" style="" behavior="scroll" direction="left" scrollamount="7"
          @mouseover="stopScroll" @mouseout="startScroll">
          <p v-for="(notification, index) in notifications" :key="index"
            style="color: var(--yellow); text-align: center;">
            <img style="width: 20px;" src="/img/icons-bell.png" />
            Congratulations! {{ notification.phone }}, won {{ notification.amount }}. {{ notification.game }}
          </p>
        </marquee>
      </div>
    </div>

    <div style="color: black; border-bottom: solid var(--light-gray) 1px" class="d-block">
      <span class="ml-2" style="color: #A0ACDC; font-weight: 600; float: left;font-size:medium;">Boosted
        Odds</span>
      <span style="margin-top: 6px; float: right;">
        <router-link to="/casino">
          <span class="mr-2" style="color: #A0ACDC; font-weight: 600;text-decoration: underline;">
            View All
            <img style="width: 20px; height: 20px;" src="/img/icons/bb-icons/icons-eye.png"
              class="menu-icon-sports-svg" />
          </span>
        </router-link>
      </span>

      <div style="width: 100%;" class="row no-gutters justify-content-center mb-2">
        <div style="border: solid var(--light-gray) 1px; border-radius: 16px" class="col p-2 mx-2">
          <div class="text-light">English Premier League</div>
          <div class="text-yellow row">
            <div class="col text-left">Arsenal</div>
            <div class="col text-right">Man U</div>
          </div>
          <div class="text-light row">
            <div class="col-4 text-center" style="padding: 10px;">
              <div>1</div>
              <button class="btn" type="button"
                style="background-color:#02032f;width: 100%;font-weight: 600;font-size: 15px;border: solid var(--light-gray) 1px;color: var(--white);border-radius: 0.5rem; text-align: center;padding: 6px;">
                2.42
              </button>
            </div>
            <div class="col-4 text-center" style="padding: 10px;">
              <div>x</div>
              <button class="btn" type="button"
                style="background-color:#02032f;width: 100%;font-weight: 600;font-size: 15px;border: solid var(--light-gray) 1px;color: var(--white);border-radius: 0.5rem; text-align: center;padding: 6px;">
                2.42
              </button>
            </div>
            <div class="col-4 text-center" style="padding: 10px;">
              <div>2</div>
              <button class="btn" type="button"
                style="background-color:#02032f;width: 100%;font-weight: 600;font-size: 15px;border: solid var(--light-gray) 1px;color: var(--white);border-radius: 0.5rem; text-align: center;padding: 6px;">
                2.42
              </button>
            </div>
          </div>
        </div>


        <div style="border: solid var(--light-gray) 1px; border-radius: 16px" class="col p-2 mx-2">
          <div class="text-light">English Premier League</div>
          <div class="text-yellow row">
            <div class="col text-left">Arsenal</div>
            <div class="col text-right">Man U</div>
          </div>
          <div class="text-light row">
            <div class="col-4 text-center" style="padding: 10px;">
              <div>1</div>
              <button class="btn" type="button"
                style="background-color:#02032f;width: 100%;font-weight: 600;font-size: 15px;border: solid var(--light-gray) 1px;color: var(--white);border-radius: 0.5rem; text-align: center;padding: 6px;">
                2.42
              </button>
            </div>
            <div class="col-4 text-center" style="padding: 10px;">
              <div>x</div>
              <button class="btn" type="button"
                style="background-color:#02032f;width: 100%;font-weight: 600;font-size: 15px;border: solid var(--light-gray) 1px;color: var(--white);border-radius: 0.5rem; text-align: center;padding: 6px;">
                2.42
              </button>
            </div>
            <div class="col-4 text-center" style="padding: 10px;">
              <div>2</div>
              <button class="btn" type="button"
                style="background-color:#02032f;width: 100%;font-weight: 600;font-size: 15px;border: solid var(--light-gray) 1px;color: var(--white);border-radius: 0.5rem; text-align: center;padding: 6px;">
                2.42
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- <Sports></Sports> -->
    <div class="buttons-sec nav-fill">
      <!-- <div class="dropdown pr-1">
          <Sports></Sports>
        </div> -->

      <div class="dropdown pr-1">
        <Sports></Sports>
      </div>

      <div class="dropdown pr-1">
        <Countries></Countries>
      </div>

      <div class="dropdown pr-1">
        <Leagues></Leagues>
      </div>

      <div class="dropdown pr-1">
        <Markets></Markets>
      </div>
    </div>

    <div id="dp" class="stick date-filter">
      <div style="margin-bottom: 5px" class="highlight-box pt-0">
        <section class="bets-menu-strip d-flex justify-content-between" style="background-color: #02032f; color: white">
          <div v-if="highlights" class="w-20 p-1 active text-center" style="background-color: #02032f; color: white">
            Highlights
          </div>
          <div v-else class="w-20 p-1 text-blue text-center" @click="setHighlight">
            <a class="text-light" style="text-decoration: none">Highlights</a>
          </div>

          <div v-if="today" class="w-20 p-1 active text-center" style="background-color: #02032f; color: white">
            Today
          </div>
          <div v-else class="w-20 p-1 text-blue text-center" @click="setToday">
            <a class="text-light" style="text-decoration: none">Today</a>
          </div>

          <div v-if="tomorrow" class="w-20 p-1 active text-center" style="background-color: #02032f; color: white">
            Tomorrow
          </div>
          <div v-else class="w-20 p-1 text-blue text-center" @click="setTomorrow">
            <a class="text-light" style="text-decoration: none">Tomorrow</a>
          </div>

          <div v-if="upcoming" class="w-20 p-1 active text-center" style="background-color: #02032f; color: white">
            Upcoming
          </div>
          <div v-else class="w-20 p-1 text-blue text-center" @click="setUpcoming">
            <a class="text-light" style="text-decoration: none">Upcoming</a>
          </div>

          <!-- <div class="w-20 p-0   text-center" @click="goTo('print')">
            <img style="width: 17px;" src="/img/BottomNav/printer-icon.png" />
          </div> -->

          <div class="w-20 p-1 px-3 text-blue d-none">Chap! Pick</div>
        </section>
      </div>
    </div>

    <Games v-bind:highlights="highlights" v-bind:today="today" v-bind:upcoming="upcoming" v-bind:tomorrow="tomorrow"
      v-bind:sport="sport_id">
    </Games>

    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
const Slider = () => import("./Slider.vue");
// const Boosted = () => import('./Boosted.vue')
const Markets = () => import("./accordion/markets.vue");
const Sports = () => import("./accordion/sports.vue");
const Countries = () => import("./accordion/countries.vue");
const Leagues = () => import("./accordion/leagues.vue");
const Games = () => import("./Games.vue");
const BottomNavigation = () => import("./BottomNavigation.vue");
import jQuery from "jquery";

const $ = jQuery;

export default {
  name: "sport",
  metaInfo: {
    title: "qwerty Sports Betting - Bet on Your Favorite Sports",
    meta: [
      {
        name: "description",
        content:
          "Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!",
      },
      {
        name: "keywords",
        content:
          "Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting," +
          "Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In- play betting, Cash out, Live odds, Betting tips, Aviator," +
          "Jetx, Cometcrash, Spin2wiin, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support," +
          "Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score",
      },
    ],
  },
  components: {
    Leagues,
    Countries,
    Markets,
    Sports,
    Slider,
    Games,
    BottomNavigation,
  },

  data: function () {
    return {
      highlights:
        this.$store.state.current_tab === "highlights" ||
        !this.$store.state.current_tab,
      upcoming: this.$store.state.current_tab === "upcoming",
      tomorrow: this.$store.state.current_tab === "tomorrow",
      today: this.$store.state.current_tab === "today",
      sub_page: "",
      mqttClient: "",
      homePagePopularGames: [
        {
          providerID: 7,
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
          crashGameUrl: "aviator",
        },
        {
          providerID: 5,
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
          crashGameUrl: "jetx",
        },
        {
          providerID: 3,
          gameID: "1",
          gameName: "Comet",
          image: "/img/home/CometCrash.jpg",
          crashGameUrl: "comet",
        },
        {
          providerID: 4,
          gameID: "rocketman",
          gameName: "RocketMan",
          image: "/img/home/Rocketman.jpg",
          crashGameUrl: "rocketman",
        },
      ],
      notifications: [
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Easter Plinko' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Statch Alpaca' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Comet crash' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Capymania Green' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Mine sweeper' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Limbo' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Heads Tails' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Dragons Crash' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' French Roulette' },
      ]
    };
  },
  mounted() {
    this.$store.dispatch("setHour", -1);
    this.$store.dispatch("setCompetitionID", 0);
    this.reloadProfile();
    this.$store.dispatch("setCurrentPage", "sport");
  },
  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
  },
  methods: {
    randomizePhone() {
      const randomDigits = Math.floor(Math.random() * 90 + 10);
      return `254****${randomDigits}`;
    },
    randomizeCash() {
      const randomDigits = Math.floor(Math.random() * 90 + 200);
      return `Ksh. ${randomDigits}`;
    },
    stopScroll(event) {
      event.target.stop();
    },
    startScroll(event) {
      event.target.start();
    },
    truncateText(text) {
      return text.length > 16 ? text.substring(0, 10) + '...' : text;
    },
    getLaunchURL: function (launch_url) {
      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    generateGameUrl(game) {
      return `/casinogames?IsDemo=0&providerID=${game.provider_id}&gameName=${game.game_name}&gameID=${game.game_id}&launchURL=${game.launch_url}&launchType=${game.launch_type}&launchParameters=${game.launch_parameters}`;
    },
    setUpcoming: function () {
      this.$store.dispatch("setCurrentTab", "upcoming");
      this.highlights = false;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = true;
    },
    setToday: function () {
      this.$store.dispatch("setCurrentTab", "today");
      this.highlights = false;
      this.today = true;
      this.tomorrow = false;
      this.upcoming = false;
    },
    setTomorrow: function () {
      this.$store.dispatch("setCurrentTab", "tomorrow");
      this.highlights = false;
      this.today = false;
      this.tomorrow = true;
      this.upcoming = false;
    },
    setHighlight: function () {
      this.$store.dispatch("setCurrentTab", "highlights");
      this.highlights = true;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = false;
    },
    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    isHighlight: function () {
      return this.highlights;
    },
    isToday: function () {
      return this.today;
    },
    isUpcoming: function () {
      return this.upcoming;
    },

    isTomorrow: function () {
      return this.tomorrow;
    },

    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },
  },
};

window.addEventListener("scroll", () => {
  //var viewportOject = $('#infoY').offset().top - 50;
  var scrollPos = $(document).scrollTop();
  //console.log("vv"+viewportOject);
  //console.log("SS"+scrollPos);
  if (scrollPos >= 150) {
    // $("#dp").addClass("stick");
    //alert("I am here");
  } else {
    // $("#dp").removeClass("stick");
  }
});
</script>

<style scoped>
.marquee-container {
  height: 25px;
  margin-top: 0px;
  padding: 2px;
  background: var(--darkBackground);
  width: 100%;
  border-radius: 8px;
  padding: 3px;
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.games-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  gap: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.games-container::-webkit-scrollbar {
  display: none;
}

.game-image-container {
  width: 120px;
  height: 140px;
}

.game-item {
  min-width: 104px;
  flex: 0 0 auto;
  text-align: center;
}

.game-icon img {
  position: relative;
  width: 140px;
  height: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);
}

.list-active {
  background-color: #f08b05;
}

.custom-divider {
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
}

.mobile-container {
  display: flex;
  flex-wrap: wrap;
}

.mobile-top-left-corner {
  background-color: white;
  border-top: 1.5px solid rgba(209, 201, 201, 0.979);
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-top-left-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-top-right-corner {
  background-color: white;
  border-top: 1.5px solid rgba(209, 201, 201, 0.979);
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-right: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-top-right-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-bottom-left-corner {
  background-color: white;
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom-left-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-bottom-right-corner {
  background-color: white;
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-right: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom-right-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

span {
  font-weight: 700;
}

.date-filter {
  padding: 5px 0.5rem;
  border-top: 1px var(--light-gray) solid;
  border-bottom: 1px var(--light-gray) solid;
}
</style>
